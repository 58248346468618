<template>
  <header class="tour-loading-header">
    <h1 class="tour-loading-header__title" data-e2e="tour-loading-title">
      {{ title }}
    </h1>
    <h3 class="tour-loading-header__subtitle" data-e2e="tour-loading-subtitle">
      <slot />
    </h3>
    <div class="tour-loading-header__close" @click="showDiscardDialog">
      <clear-icon
        class="tour-loading-header__close--btn"
        data-e2e="header-close-button"
        stroke="#0D3A93"
        fill="#0D3A93"
      />
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ClearIcon } from '@/features/ui';
import { useRouter } from 'vue-router';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'TourLoadingHeader',
  components: { ClearIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    const router = useRouter();

    const showDiscardDialog = async () => {
      const confirmed = await useDynamicDialog().confirm({
        title: $t(
          'components.tour-picking-receptacles-header.discard-dialog-title.text',
        ),
        confirmText: $t(
          'components.tour-picking-receptacles-header.discard-dialog-confirm-text.text',
        ),
        contentText: $t(
          'components.tour-picking-receptacles-header.discard-dialog-content-text.text',
        ),
        cancelText: $t(
          'components.tour-picking-receptacles-header.discard-dialog-cancel-text.text',
        ),
      });
      if (confirmed) {
        router.back();
      }
    };

    return {
      showDiscardDialog,
    };
  },
});
</script>

<style lang="scss" scoped>
.tour-loading-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  flex-direction: column;

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.015em;
  }

  &__close {
    &--btn {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: 0;
      outline: 0;
      cursor: pointer;
    }
  }
}
</style>
